.checkbox-wrapper-54 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-54 *,
.checkbox-wrapper-54 ::after,
.checkbox-wrapper-54 ::before {
  box-sizing: border-box;
}

/* The switch - the box around the slider */
// .checkbox-wrapper-54 .switch {
//   --width-of-switch: 3.5em;
//   --height-of-switch: 2em;

//   --size-of-icon: 1.4em;

//   --slider-offset: 0.3em;
//   position: relative;
//   width: var(--width-of-switch);
//   height: var(--height-of-switch);
//   display: inline-block;
// }

/* The slider */
.checkbox-wrapper-54 .slider {
  background-color: #edf7ff;
  transition: 0.4s;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  cursor: pointer;
  img {
    width: 20px;
  }
  svg {
    font-size: 20px;
    color: #ffaa00;
    animation: rotation 2s infinite linear;
    &:hover {
      transform: scale(1.2);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

// .checkbox-wrapper-54 .slider:before {
//   position: absolute;
//   content: "";
//   height: var(--size-of-icon, 1.4em);
//   width: var(--size-of-icon, 1.4em);
//   border-radius: 20px;
//   left: var(--slider-offset, 0.3em);
//   top: 50%;
//   transform: translateY(-50%);
//   background: linear-gradient(40deg, #ff0080, #ff8c00 70%);
//   transition: 0.4s;
// }

.checkbox-wrapper-54 input:checked + .slider {
  background-color: #072341;
  svg {
    color: #cbe8ff;
    animation: colorchanging 0.5s infinite linear;
    @keyframes colorchanging {
      from {
        color: #ffffff;
      }
      to {
        color: #8cc6f6;
      }
    }
  }
}

// .checkbox-wrapper-54 input:checked + .slider:before {
//   // left: calc(100% - (var(--size-of-icon, 1.4em) + var(--slider-offset, 0.3em)));
//   background: #303136;
//   /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
//   box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
// }
.theme-btn {
  z-index: 9999;
  position: fixed;
  bottom: 25px;
  left: 25px;
  right: auto;
  top: auto;
}
#light-dark-tool {
  .tooltip-arrow {
    &::before {
      border-top-color: #3e5060eb;
    }
  }
  .tooltip-inner {
    background-color: #3e5060eb;
    font-family: "SegoeUI-SemiBold", "Poppins", sans-serif;
    font-size: 13px;
  }
}
